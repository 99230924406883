import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { type: String }

  connect() {

    this.element.addEventListener('animationend', (e) => {
      if (e.animationName == "fade-out") {
        this.element.remove();
      }
    });

    if (this.typeValue == "success") {
      setTimeout(() => { this.remove() }, 4000);
    }

  }

  remove() {
    this.element.classList.add("fade-out")
  }

}
