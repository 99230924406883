import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import { getMetaValue, findElement, removeElement, insertAfter } from "../helpers"

Dropzone.autoDiscover = false

export default class extends Controller {

  static targets = [ "input" ]

  connect() {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

  // Private

  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on("processing", (_file) => {
      this.submitButton.disabled = true
    })

    this.dropZone.on("queuecomplete", (_file) => {
      this.submitButton.disabled = false
      if (this.data.get("submitOnQueuecomplete") == "true") {
        this.submitButton.click()
      }
    })

    // We listen for `drop` on the element to handle non-file drops.  Dropzone
    // will still handle file drops, and it stops propogation of the `drop`
    // event if the dropped item is a file.  This means our handler will only be
    // called for non-file drops, e.g. when dragging in Front attachment, which
    // presents as a URL DataTransferItem.

    // In order to handle the drop event, we must cancel dragenter and dragover
    this.element.addEventListener("dragenter", (event) => {
      event.preventDefault()
    });

    this.element.addEventListener("dragover", (event) => {
      event.preventDefault()
    });

    this.element.addEventListener("drop", (event) => {
      console.log("handling drop of non-file item")

      // There may be multiple types of items dropped.
      // We are only interested in items with type "text/uri-list"
      let items = event.dataTransfer.items

      for(let i=0; i<items.length; i++) {
        if (items[i].type == "text/uri-list") {
          console.log("found text/uri-list item")
          items[i].getAsString(url => {
            console.log("text/uri-list item: " + url)

            // We can only process URLs that have the resource_link_id query
            // parameter
            //
            // e.g. https://app.frontapp.com/us-west-1/api/1/companies/wizard_pins/attachments/4ffd36031c9383df0b4c06fd1df40aa9da508391?resource_link_id=61608147475
            //
            // If it has it, we will extract the value and use it in a fetch
            // call to our proxy endpoint, which will fetch the attachment from
            // Front and return it as a blob.

            let urlParams = new URLSearchParams(url.split("?")[1])
            let resourceLinkId = urlParams.get("resource_link_id")
            if (!resourceLinkId) {
              console.log("no resource_link_id found in URL")
              return
            }

            // Construct the proxy endpoint url
            let proxyUrl = "/front_attachment_proxy/" + resourceLinkId

            // Add a spinner to the dropzone
            // The spinner is removed by the way dropzone works upon success
            let spinnerHtml = '<div class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-indigo-600 rounded-full dark:text-indigo-500" role="status" aria-label="loading"><span class="sr-only">Loading...</span></div>'
            let spinner = this.element.appendChild(document.createElement("div"))
            spinner.classList.add("absolute", "top-1/2", "left-1/2", "-translate-x-1/2", "-translate-y-1/2")
            spinner.innerHTML = spinnerHtml

            // Fetch the attachment via proxy endpoint and add it to dropzone as
            // a file
            fetch(proxyUrl)
              .then(response => response.blob())
              .then(blob => {
                let file = new File([blob], "attachment")
                this.dropZone.addFile(file)
              })

          })
        }
      }

    });

  }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }

  get maxFiles() { return this.data.get("maxFiles") || 1 }

  get maxFileSize() { return this.data.get("maxFileSize") || 256 }

  get acceptedFiles() { return this.data.get("acceptedFiles") }

  get addRemoveLinks() { return this.data.get("addRemoveLinks") || true }

  get form() { return this.element.closest("form") }

  get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

// Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    clickable: controller.data.get("clickable") == "true",
    autoQueue: false
  })
}
