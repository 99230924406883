import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="frame-sync"
export default class extends Controller {

  static targets = ["dependent"]

  connect() {

    var refreshMe = this.dependentTargets

    this.element.addEventListener("turbo:submit-end", (e) => {
      refreshMe.forEach((turboframe) => {
        if (
          this.isFormKeySatisfied(e.target, turboframe) &&
          this.isNotDisabledForm(e.target)
        ) {
          this.syncFrame(turboframe)
        }
      })
    });

  }

  isNotDisabledForm(form) {
    return form.dataset.frameSyncDisable != "true"
  }

  // Given a form that was submitted, and a turboframe, we only want to refresh
  // the turboframe if
  // 1) the turboframe indicates no key needed (doesn't have the attribute)
  // 2) the key values match for the form and the turboframe
  isFormKeySatisfied(form, turboframe) {
    if(!form) { return false } // no form? no sync
    if(!turboframe.dataset.frameSyncFormKey) { return true } // no key? sync
    if(turboframe.dataset.frameSyncFormKey == form.dataset.frameSyncFormKey) { return true } // keys match? sync
    return false
  }

  syncFrame(turboframe) {
    console.log("syncing frame %o", turboframe)
    if(turboframe.dataset.frameSyncSrc) {
      turboframe.src = turboframe.dataset.frameSyncSrc
      delete turboframe.dataset.frameSyncSrc
    }
    else {
      turboframe.reload()
    }
  }

}
