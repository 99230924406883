import { Controller } from "@hotwired/stimulus"
import Front from "@frontapp/plugin-sdk"
import { template } from "lodash"

// Connects to data-controller="frontapp-attachment-select"
export default class extends Controller {

  static targets = ["template", "allTemplate"]

  initialize() {
    // https://lodash.com/docs/4.17.15#template
    // Convert the contents of the template tag into a lodash template:
    let templateHtml;

    if (this.hasAllTemplateTarget) {
      templateHtml = this.allTemplateTarget.innerHTML
    }
    else {
      templateHtml = this.templateTarget.innerHTML
    }
    templateHtml = templateHtml.replaceAll("&lt;", "<").replaceAll("&gt;", ">")
    this.templateFn = template(templateHtml)
  }

  connect() {

    Front.listMessages().then(resp => {

      let messages = resp.results
      let attachments = []

      messages.forEach (message => {
        message.content.attachments.forEach (attachment => {

            let info = {
              messageId: message.id,
              attachmentId: attachment.id,
              name: attachment.name,
              size: attachment.size,
              contentType: attachment.contentType
            }

            if (attachments.length <= 10) {
              attachments.push(info)
              if (this.hasTemplateTarget) {
                this.element.innerHTML += this.templateFn(info)
              }
            }

        })
      })

      if (this.hasAllTemplateTarget) {
        this.element.innerHTML = this.templateFn({ attachments: attachments })
      }

    })
  }

}
