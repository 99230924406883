import { Controller } from "@hotwired/stimulus"

let eventListenerAdded = false;
let closeModalEventFn = null;

// Connects to data-controller="main-turbo-modal"
export default class extends Controller {

  connect() {

    // The controller should be connected to <a> tags or <form> tags.
    //
    // When the tag is clicked, the modal is instantly opened (showing a
    // "loading..." message), and the linked page is expected to respond with a
    // <turbo-frame> tag with the id "main-turbo-modal-frame".
    //
    // Similar for a form, but on submission vs click.
    if (this.element.tagName === "A" && this.element.href) {
      this.element.dataset.turboFrame = "main-turbo-modal-frame"
      this.element.addEventListener("click", (_evt) => {
        this.openModal()
      })
    } else if (this.element.tagName === "FORM") {
      this.element.dataset.turboFrame = "main-turbo-modal-frame"
      this.element.addEventListener("submit", (_evt) => {
        this.openModal()
      })
    }

    // Invoking this controller will also ensure that event listeners are added
    // to the checkbox and the document.
    if (!eventListenerAdded) {

      // You can close the modal by dispatching a "main-turbo-modal:close"
      // event.
      closeModalEventFn = () => { this.closeModal() }
      document.addEventListener("main-turbo-modal:close", closeModalEventFn);

      // If the checkbox is changed directly (e.g. by clicking on the "X" icon),
      // we detect whether it is checked or not, and prepare the modal for the
      // next open if not.
      this.checkBoxEl().addEventListener("change", (_evt) => {
        if (!this.checkBoxEl().checked) {
          this.prepareModalForNextOpen()
        }
      })

      eventListenerAdded = true;
    }

  }

  openModal() {
    this.checkBoxEl().checked = true
  }

  closeModal() {
    this.checkBoxEl().checked = false
    this.prepareModalForNextOpen()
  }

  checkBoxEl() {
    return document.querySelector('#main-turbo-modal-checkbox')
  }

  turboFrameEl() {
    return document.querySelector('#main-turbo-modal-frame')
  }

  prepareModalForNextOpen() {
    setTimeout(() => {
      console.log("resetting modal frame")
      this.turboFrameEl().innerHTML = "<div class=\"text-center text-gray-400\">Loading...</div>"
    }, 300)
  }

}
