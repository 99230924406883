import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["changer", "clicker"]

  connect() {
    this.changerTargets.forEach((el) => {
      let eventName = el.dataset.changeToClickEventName || "change"
      let delay = Number(el.dataset.changeToClickDelay || 0)
      let eventNames = eventName.split(" ")
      eventNames.forEach((eventName) => {
        el.addEventListener(eventName, (_evt) => {
          if(el.validity && !el.validity.valid) return
          if(this.changeToClicked) return
          this.clickTargets(delay)
          this.changeToClicked = true
        })
      })
    })
  }

  clickTargets(delayValue) {
    this.clickerTargets.forEach((el) => {
      if (delayValue > 0) {
        setTimeout(() => { el.click(); }, delayValue)
      }
      else {
        el.click();
      }
    })
  }

}

