// Ref: https://edforshaw.co.uk/hotwire-turbo-stream-animations
document.addEventListener("turbo:before-stream-render", function(event) {

  // Add a class to an element we are about to add to the page
  // as defined by its "data-stream-enter-class"
  if(["append", "prepend", "before", "after"].indexOf(event.target.action) >= 0) {
    if (event.target.firstElementChild instanceof HTMLTemplateElement) {
      // event.target.firstElementChild.content.querySelectorAll("[data-stream-enter-class]").forEach(element => {
      //   element.classList.add(element.dataset.streamEnterClass)
      // })
      var enterAnimationClass = event.target.templateContent.firstElementChild.dataset.streamEnterClass
      if (enterAnimationClass) {
        event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass)
      }
    }
  }

  if(["update","replace"].indexOf(event.target.action) >= 0) {
    if (event.target.firstElementChild instanceof HTMLTemplateElement) {
      var updateAnimationClass = event.target.templateContent.firstElementChild.dataset.streamUpdateClass
      if (updateAnimationClass) {
        event.target.templateElement.content.firstElementChild.classList.add(updateAnimationClass)
      }
    }
  }

  // Add a class to an element we are about to remove from the page
  // as defined by its "data-stream-exit-class"
  if(event.target.action == "remove") {

    if(event.target.target) {
      var elementToRemove = document.getElementById(event.target.target)
    }
    else {
      var elementToRemove = document.querySelector(event.target.targets)
    }
    var streamExitClass = elementToRemove.dataset.streamExitClass

    // The callback is "onced" so that in the event of multiple animationend
    // events (because more than one animation can fire at a time), only the
    // first one will performAction.
    var callbackFn = function() {
      event.target.performAction()
      callbackFn = function() {}
    }

    if (streamExitClass) {
      // Intercept the removal of the element
      event.preventDefault()
      elementToRemove.classList.add(streamExitClass)

      // Wait for its animation to end before removing the element
      elementToRemove.addEventListener("animationend", (e) => callbackFn(e))
    }

  }

})
