import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message"
export default class extends Controller {

  static targets = ["deleteControl", "editControl"]

  static values = {
    "authorId": String,
    "authorName": String,
    "authorRole": String,
    "sentAt": String,
    "topicId": String,
    "showAsAuthored": { type: Boolean, default: false }
  }

  initialize() {

    let currUserEl = this.element.closest("[data-current-user-id]")
    this.currentUserId = currUserEl ? currUserEl.dataset.currentUserId : null

    let currRoleEl = this.element.closest("[data-current-user-role]")
    this.currentUserRole = currRoleEl ? currRoleEl.dataset.currentUserRole : null

    let lastVisitedEl = this.element.closest("[data-last-visited-message-topic-at]")
    this.lastVisitedAt = lastVisitedEl ? new Date(lastVisitedEl.dataset.lastVisitedMessageTopicAt) : null

  }

  connect() {
    var previousNode = this.element.previousElementSibling

    // unstack repeat names
    //
    // if the previous node has the same data-message-author-id-value then
    // remove the author name element
    if (previousNode && previousNode.dataset.messageAuthorIdValue == this.authorIdValue) {
      var el = this.element.querySelector(".message-bubble-author-name")
      if (el) { el.remove() }
    }

    // remove excessive timestamps
    if (previousNode && previousNode.dataset.messageSentAtValue) {
      let prevSentAt = new Date(previousNode.dataset.messageSentAtValue)
      let currSentAt = new Date(this.sentAtValue)
      // if prevSentAt within 9 minutes of this.sentAt then remove the timestamp
      if (Math.abs(currSentAt - prevSentAt) < 9 * 60 * 1000) {
        var el = this.element.querySelector(".message-bubble-sent-at")
        if (el) { el.remove() }
      }
    }

    // Remove Edit/Delete controls if not authored by current user
    if (this.authorIdValue != this.currentUserId) {
      if (this.hasDeleteControlTarget) { this.deleteControlTarget.remove() }
      if (this.hasEditControlTarget) { this.editControlTarget.remove() }
    }

    // Classify it as authored or received
    //
    // If the message was authored by current user, it's blue and on the right.
    // If the message was authored by staff, it's gray and on the right.
    // If the message was authored by an artist, it's gray and on the left.
    // If the message is on the customer's behalf, it's green and on the left.
    //
    //             Left  |  Right
    //        +----------+---------+
    //  Blue  |          |  Self   |                     .authored-message
    //        +----------+---------+
    //  Gray  | External |  Team   |  .external-message  .team-message
    //        +----------+---------+
    //  Green | Customer |         |  .customer-message
    //        +----------+---------+
    //
    if (this.authorIdValue == this.currentUserId || this.showAsAuthoredValue) {
      this.element.classList.add("authored-message")
    }
    else if (this.authorRoleValue && this.authorRoleValue != "") {
      if (this.authorRoleValue == this.currentUserRole) {
        this.element.classList.add("team-message")
      }
      else {
        this.element.classList.add("external-message")
      }
    }
    else {
      this.element.classList.add("customer-message")
    }

    // Register the visit if it's newer than the current user's last visit
    if (this.topicIdValue && this.lastVisitedAt && this.sentAtValue && this.lastVisitedAt < new Date(this.sentAtValue)) {
      fetch("/message_topics/" + this.topicIdValue + "/register_visit", {
        method: "POST"
      })
    }

  }

}
