// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./channels"
import "./stream_animations"
import "trix"
import "@rails/actiontext"

window.fetchTurboStream = function(path) {
  fetch(path, {
    headers: { Accept: "text/vnd.turbo-stream.html" },
  })
  .then(r => {
    if(r.ok) {
      r.text().then(html => Turbo.renderStreamMessage(html))
    } else {
    }
  })
}
