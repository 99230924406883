import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click-utils"
export default class extends Controller {

  static outlets = ["reveal"]

  connect() {
  }

  controlReveal() {
    this.revealOutlets.forEach((outlet) => {
      outlet.toggle()
    })
  }

}
