import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="smart-scroll"
export default class extends Controller {

  connect() {

    this.resizeObserver = new ResizeObserver(entries => {
      for (let _entry of entries) {
        this.dispatch("resize")
      }
    })

    this.mutationObserver = new MutationObserver(entries => {
      for (let _entry of entries) {
        this.dispatch("mutation")
      }
    })

    this.resizeObserver.observe(this.element)
    this.mutationObserver.observe(this.element, { childList: true })

    setTimeout(() => {
      this.shouldScroll = true
      this.scrollToBottom()
    }, 1000);
  }

  disconnect() {
    this.resizeObserver.disconnect()
    this.mutationObserver.disconnect()
  }

  scrollToBottom() {
    const { scrollHeight, clientHeight } = this.element
    if (scrollHeight >= clientHeight) {
      this.element.scrollTop = scrollHeight - clientHeight
    }
  }

  handleAdded() {
    const { scrollHeight, clientHeight } = this.element
    if (clientHeight >= scrollHeight) {
      this.shouldScroll = true
    }

    if (this.shouldScroll) {
      this.scrollToBottom()
    }
  }

  handleScroll() {
    const { scrollTop, scrollHeight, clientHeight, offsetHeight } = this.element
    if (clientHeight >= scrollHeight) {
      this.shouldScroll = true
    } else {
      this.shouldScroll =
        Math.abs((scrollTop + offsetHeight) - scrollHeight) < 1
    }
  }

}
