import { Controller } from "@hotwired/stimulus"

const selectedClass = "selected-opportunity";

// Connects to data-controller="staff-app--opportunities"
export default class extends Controller {

  static targets = ["main", "sidePanel"];

  static sidePanelHiddenClasses = "w-0"
  static sidePanelShownClasses = "w-[400px]"

  connect() {
    this.closeSidePanel(new CustomEvent("click"));
  }

  clearSelection() {
    // Remove selectedClass from any elements that have it
    document.querySelectorAll(`.${selectedClass}`).forEach((el) => {
      el.classList.remove(selectedClass);
    });
  }

  selectOrder(event) {

    event.preventDefault();

    this.clearSelection();

    // Add selectedClass to the clicked row
    const tr = event.currentTarget.closest("tr");
    tr.classList.add(selectedClass);

    // Get the url from the currentTarget
    var href = event.currentTarget.getAttribute("href");

    // Find iframe element with name "side_panel"
    var iframeEl = document.querySelector("iframe[name='side_panel']");
    iframeEl.src = href;

    // Ensure sidePanel is visible
    this.sidePanelTarget.classList.remove(this.constructor.sidePanelHiddenClasses);
    this.sidePanelTarget.classList.add(this.constructor.sidePanelShownClasses);

  }

  closeSidePanel(event) {
    event.preventDefault();

    this.clearSelection();

    // Hide side panel
    this.sidePanelTarget.classList.remove(this.constructor.sidePanelShownClasses);
    this.sidePanelTarget.classList.add(this.constructor.sidePanelHiddenClasses);
  }

}
