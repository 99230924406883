import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-on-click"
export default class extends Controller {

  connect() {
    var button = this.element
    var form = button.closest("form")

    form.addEventListener("turbo:submit-start", (evt) => {
      if(evt.target != form) { return }

      if (button.dataset.disableWith) {
        button.textContent = button.dataset.disableWith
        if (button.value) {
          button.value = button.dataset.disableWith
        }
      }

      if (button.dataset.disableWithClass) {
        button.classList.add(button.dataset.disableWithClass)
      }

      button.disabled = true
    })

    // Turbo automatically removes the disabled attribute when the form
    // submission ends.
    //
    // https://github.com/hotwired/turbo/blob/2b41a936163eaa3bfc2e7b3bb05dbe7ef803303b/src/core/drive/form_submission.ts#L204
    //
    // So we fight that here.
    form.addEventListener("turbo:submit-end", (evt) => {
      if(evt.target != form) { return }
      button.disabled = true
    })

  }

}
