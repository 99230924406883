import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.classList.add("clickable-rows");
    this.element.addEventListener("click", (evt) => {
      this.handleClick(evt);
    });
  }

  handleClick(evt) {
    let row = evt.target.closest("[data-on-row-click]")
    if(!evt.target.matches("button,input,a") && !evt.target.closest("a")) {
      if(row.dataset.rowTurboClick == "true") {
        let event = new CustomEvent("turbo:click", {
          bubbles: true,
          detail: { url: row.dataset.onRowClick }
        })
        setTimeout(function() {
          evt.target.dispatchEvent(event);
        });
      }
      else {
        Turbo.visit(row.dataset.onRowClick);
      }
    }
  }

}
