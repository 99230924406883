import consumer from "./consumer"

consumer.subscriptions.create("TypingMessageChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
  },

  startedTyping: function() {
    return this.perform('started_typing');
  },

  stoppedTyping: function() {
    return this.perform('stopped_typing');
  }
});
