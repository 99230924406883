import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Connects to data-controller="typing-message-indicator"
export default class extends Controller {

  static targets = ["indicator"]

  static values = {
    "messageTopicId": { type: String, default: "" }
  }

  initialize() {
    this.currentUserId = this.element.dataset.currentUserId
    this.activeTypingNames = []
    this.timer = null
  }

  connect() {
    this.subscription = consumer.subscriptions.create({
      channel: 'TypingMessageChannel', message_topic_id: this.messageTopicIdValue
    },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
    this.startedTyping()
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  startedTyping() {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {
      this.stoppedTyping()
    }, 10 * 1000)

    return this.subscription.perform('started_typing', { message_topic_id: this.messageTopicIdValue });
  }

  stoppedTyping() {
    return this.subscription.perform('stopped_typing', { message_topic_id: this.messageTopicIdValue });
  }

  updateIndicator() {
    if (this.activeTypingNames.length > 0) {
      this.indicatorTarget.innerHTML = this.activeTypingNames.join(", ") + " typing..."
    }
    else {
      this.indicatorTarget.innerHTML = ""
    }
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    if (data.user_id != this.currentUserId) {
      if (data.typing && this.activeTypingNames.indexOf(data.user_name) == -1) {
        this.activeTypingNames.push(data.user_name)
      }
      else if (!data.typing) {
        this.activeTypingNames = this.activeTypingNames.filter(i => i != data.user_name)
      }
    }
    this.updateIndicator()
  }

}
