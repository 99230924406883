var scrollPositions = {};

addEventListener("turbo:before-render", function(){
  document.querySelectorAll("[data-persist-scroll]").forEach(function(element){
    scrollPositions[element.id] = element.scrollTop;
  });
});

addEventListener("turbo:render", function(){
  document.querySelectorAll("[data-persist-scroll]").forEach(function(element){
    setTimeout(() => {
      element.scrollTop = scrollPositions[element.id];
    }, 0)
  });
});
