import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wait-for-job"
export default class extends Controller {

  // Expects to be connected to a turboframe.
  //
  // Intercepts fetch responses and checks for a special response that indicates
  // a Job was kicked off.
  //
  // If it's not a special "Job" response, default TurboFrame behavior.
  //
  // If a job is detected,
  // * cuts off the default behavior and...
  // * polls job_status_url for update
  // * when job seems done, then either
  // ** loads indicated frame_src into turboframe , or
  // ** sets indicated location_url into window.location
  //
  connect() {

    var _this = this

    this.element.addEventListener("turbo:before-fetch-response", async(e) => {

      // If defaultPrevented, then we've already handled this event.
      if (e.defaultPrevented) { return; }

      var statusCode = e.detail.fetchResponse.statusCode
      var isWaitForJob = e.detail.fetchResponse.header("X-Wait-For-Job")
      var turboFrameEl = _this.element

      if (statusCode == 202 && isWaitForJob) {
        _this.waitForJob(e.detail.fetchResponse.response, turboFrameEl)
        e.preventDefault()
      }
      else if (statusCode == 303 && isWaitForJob == "dummy") {
        var jsn = await e.detail.fetchResponse.response.json()

        if (jsn.location) {
          var location_url = new URL(jsn.location)
          if (location_url.host != window.location.host) {
            e.preventDefault()
            if (jsn.target == "_blank") {
              window.open(location_url)
            } else {
              window.location.assign(location_url)
            }
          }
        }
        else if (jsn.frame_src) {
          e.preventDefault()
          turboFrameEl.src = jsn.frame_src
          turboFrameEl.dispatchEvent(new CustomEvent("wait-for-job:completed"))
        }
      }
    })
  }

  async waitForJob(response, turboFrameEl) {
    var json = await response.clone().json()
    var job_status_url = json.job_status_url
    var location_url   = json.location
    var frame_src_url  = json.frame_src


    var handler = async() => {
      var r = await fetch(job_status_url)
      var j = await r.json()
      if (j.job_status == "complete") {
        if (location_url) {
          if (json.target == "_blank") {
            window.open(location_url)
          } else {
            window.location.assign(location_url)
          }
        }
        else if (frame_src_url) {
          turboFrameEl.src = frame_src_url
        }

        turboFrameEl.dispatchEvent(new CustomEvent("wait-for-job:completed"))
      }
      else {
        setTimeout(handler, 1000)
      }
    }

    handler()
  }

}
